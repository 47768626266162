import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';
// import { SettingDrawer } from '@ant-design/pro-layout';
import { history } from 'umi';
import RightContent from '@/components/RightContent';
import { currentUser as queryCurrentUser } from './services/ant-design-pro/api';
const loginPath = '/user/login';
import type { RequestConfig } from 'umi';

import HeaderContent from './components/HeaderContent';
import { getMenus } from './services/ant-design-pro/menu';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  collapsed?: boolean;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      return msg.data;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  // 如果是登录页面，不执行
  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    return { fetchUserInfo, currentUser, settings: {} };
  }
  return { fetchUserInfo, settings: {} };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  sessionStorage.setItem('realName', initialState?.currentUser?.realName || '');
  const onCollapse = (collapsed: boolean): void => {
    // localStorage.setItem('collapsed', collapsed)
    setInitialState({
      ...initialState,
      collapsed,
    }).then();
  };
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    waterMarkProps: {
      content: initialState?.currentUser?.realName,
    },
    // footerRender: () => <Footer />,
    footerRender: false,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        // history.push(loginPath);
      }
    },
    // menuHeaderRender: () => <MenuHeader />,
    menu: {
      // 每当 initialState?.currentUser?.userId 发生修改时重新执行 request
      params: {
        userId: initialState?.currentUser?.userId,
      },
      request: async () => {
        if (!initialState?.currentUser?.userId) {
          return [];
        }
        // 动态获取菜单
        return getMenus();
      },
      locale: false,
    },
    headerContentRender: () => (
      <HeaderContent collapse={initialState?.collapsed} onCollapse={onCollapse} />
    ),
    // 去掉系统自带
    collapsedButtonRender: false,
    // 指定配置collapsed
    collapsed: initialState?.collapsed,
    // 菜单的折叠收起事件
    onCollapse: onCollapse,
    // 增加一个 loading 的状态
    childrenRender: (children, props) => {
      console.log(props);
      // console.log(!props.location?.pathname?.includes('/user/login'));

      // if (initialState?.loading) return <PageLoading />;
      return (
        <>
          <div className={initialState?.collapsed ? 'collapsedContent' : 'noCollapsedContent'}>
            {children}
          </div>

          {/* {props.location?.pathname != '/user/login' && (
            <SettingDrawer
              disableUrlParams
              enableDarkTheme
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                setInitialState((preInitialState) => ({
                  ...preInitialState,
                  settings,
                }));
              }}
            />
          )} */}
        </>
      );
    },
    ...initialState?.settings,
  };
};

// // 响应拦截
async function responseInterceptors(response: Response) {
  if (response.headers.get('Content-Type') == 'application/json;charset=UTF-8') {
    const data = await response.clone().json();
    if (data.code == 401) {
      sessionStorage.setItem('loginErrMsg', '登录失效，请重新登录');
      history.push(loginPath);
    }
    return response;
  } else {
    return response;
  }
  // if (response.headers.get('Content-Type') == 'image/jpeg') {
  //   return response;
  // } else {
  //   const data = await response.clone().json();
  //   if (data.code == 401) {
  //     sessionStorage.setItem('loginErrMsg', '登录失效，请重新登录');
  //     history.push(loginPath);
  //   }
  //   return response;
  // }
}

// 为请求设置允许携带cookie
export const request: RequestConfig = {
  credentials: 'include', // 默认请求是否带上cookie,
  headers: {
    Accept: 'application/json',
  },
  responseInterceptors: [responseInterceptors],
};
