import { message } from 'antd';
import { request } from 'umi';
// 交易监控services
/** 获取交易监控列表 */
export async function getList(body: any) {
  const { times } = body;
  const params = {
    ...body,
    // pageNumber: body.current,

    startTransDate: times?.length > 0 ? times[0] : '',
    endTransDate: times?.length > 0 ? times[1] : '',
  };

  delete params.current;
  delete params.times;
  const response = await request(`${BASE_URL}/trans/getMonitorPagList`, {
    method: 'POST',
    data: params,
  });
  if (!response?.successful) {
    message.error(response?.msg);
  }
  return {
    data: response.data?.items || [],
    total: response.data?.totalElements,
    success: response.successful,
  };
}
export async function getSalesLog (body: any) {
  const res = await request(`${BASE_URL}/trans/getSalesLog `, {
    method: 'POST',
    data: body,
  });
  if (!res?.successful) {
    message.error(res?.msg);
  }
  console.log(res?.data);
}
/** 交易监控详情查询 */
export async function getTransaction(body: any) {
  const res = await request(`${BASE_URL}/trans/getSalesDetails`, {
    method: 'POST',
    data: body,
  });
  if (!res?.successful) {
    message.error(res?.msg);
  }
  console.log(res?.data);

  // const obj = {
  //   shopNames: res?.data?.shopNames || 'kfc',
  //   amount: res?.data?.amount || 1,
  //   salesState: res?.data?.salesStateDesc || '成功',
  //   posSn: res?.data?.posSn,
  //   posCode: res?.data?.posCode,
  //   transTime: res?.data?.transTime,
  //   paymentDesc: res?.data?.amount,
  //   ticketCodes: res?.data?.ticketCodes[0],
  //   deviceTypeName: '联迪A8',
  //   versionCode: res?.data?.versionCode || 'V1.0.0'

  // }
  // return obj;
  return res.data || {};
}

// 日志列表
export async function getLogList(body: any) {
  const res = await request(`${BASE_URL}/trans/getPagList`, {
    method: 'POST',
    data: body,
  });
  return res.data || [];
}
