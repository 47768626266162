// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

/** 获取当前的用户 GET /current/getCurrUser */
export async function currentUser() {
  return request(`${BASE_URL}/current/getCurrUser`);
}

/** 退出登录接口 POST /ess/logout */
export async function outLogin() {
  return request<Record<string, any>>(`${BASE_URL}/ess/logout`, {
    method: 'POST',
  });
}

/** 登录接口 POST /api/login/account */
export async function login(body: any, options?: { [key: string]: any }) {
  // if (body.identityType == 1) {
  return request(`${BASE_URL}/ess/login`, {
    method: 'POST',
    data: { ...body, userInputCode: body.InputCode },
  });
  // } else {
  //   return request(`${BASE_URL}/ess/mobileLogin`, {
  //     method: 'POST',
  //     params: { ...body },
  //   });
  // }
}
// 请求验证码图片
export async function getFakeCaptcha() {
  return request(`${BASE_URL}/ess/captcha`, {
    responseType: 'blob',
  });
}
// 请求二维码地址
export async function getQrurl() {
  return request(`${BASE_URL}/ess/buildQrConnectUrl`);
}

/** 发送手机验证码 */
export async function getSMSVcode(body: any) {
  const res = await request(`${BASE_URL}/ess/getSMSVcode`, {
    method: 'POST',
    data: {
      // ...body,
      mobile: body?.account,
      captchaVerification: body?.captchaVerification,
    },
  });
  return res;
}
/** 获取微信登录码 */
export async function wechatCode() {
  const response = await request(`${BASE_URL}/ess/buildQrConnectUrl`);
  return response.data || '';
}
/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取规则列表 GET /api/rule */
export async function rule(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('/api/rule', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 新建规则 PUT /api/rule */
export async function updateRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 新建规则 POST /api/rule */
export async function addRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeRule(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/rule', {
    method: 'DELETE',
    ...(options || {}),
  });
}
