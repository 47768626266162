

const UserModel: any = {
    namespace: 'user',

    state: {
        currentUser: {},
    },

    effects: {

    },

    reducers: {
    },
};

export default UserModel;
