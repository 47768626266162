export default {
  // 表格公共语言配置
  'pages.searchTable.new': '新增',
  'pages.searchTable.name_code.search': '请输入编码/名称',
  'pages.searchTable.columns.index': '序号',
  'pages.searchTable.columns.code': '编码',
  'pages.searchTable.columns.name': '名称',
  'pages.searchTable.columns.remark': '备注',
  'pages.searchTable.columns.operator': '操作人',
  'pages.searchTable.columns.operation_time': '操作时间',
  'pages.searchTable.columns.state': '状态',
  'pages.searchTable.columns.disable': '禁用',
  'pages.searchTable.columns.enable': '启用',
  'pages.searchTable.columns.option': '操作',
  'pages.searchTable.columns.edit': '编辑',
  'pages.searchTable.columns.delete': '删除',
  'pages.searchTable.message.success': '操作成功',
  // 表单公共语言配置
  'pages.form.remark': '备注',
  'pages.form.remark.placeholder': '请输入备注',
  'pages.form.sort': '排序权重',
  'pages.form.sort.placeholder': '请输入排序权重',
  'pages.form.sort.rule': '排序权重不能为空!',
  'pages.layouts.userLayout.title': 'Eagle Soar Trends.loyalty For Saas',
  'pages.login.title': 'POS终端管理系统',
  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage': '错误的用户名和密码',
  'pages.login.failure': '登录失败，请重试！',
  'pages.login.success': '登录成功！',
  'pages.login.username.placeholder': '请输入用户名',
  'pages.login.username.required': '用户名是必填项！',
  'pages.login.password.placeholder': '请输入密码',
  'pages.login.password.required': '密码是必填项！',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机！',
  'pages.login.phoneNumber.required': '手机号是必填项！',
  'pages.login.phoneNumber.invalid': '不合法的手机号',
  'pages.login.captcha.placeholder': '请输入验证码！',
  'pages.login.captcha.required': '验证码是必填项！',
  'pages.login.capt.placeholder': '请输入图片验证码',
  'pages.login.capt.required': '图片验证码为必输项',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '自动登录',
  'pages.login.forgotPassword': '忘记密码 ?',
  'pages.login.submit': '登录',
  'pages.login.loginWith': '其他登录方式 :',
  'pages.login.registerAccount': '注册账户',
  'pages.welcome.advancedComponent': '高级表格',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.advancedLayout': '高级布局',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.searchTable.createForm.newRule': '新建规则',
  'pages.searchTable.updateForm.ruleConfig': '规则配置',
  'pages.searchTable.updateForm.basicConfig': '基本信息',
  'pages.searchTable.updateForm.ruleName.nameLabel': '规则名称',
  'pages.searchTable.updateForm.ruleName.nameRules': '请输入规则名称！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': '规则描述',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '请输入至少五个字符',
  'pages.searchTable.updateForm.ruleDesc.descRules': '请输入至少五个字符的规则描述！',
  'pages.searchTable.updateForm.ruleProps.title': '配置规则属性',
  'pages.searchTable.updateForm.object': '监控对象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': '规则模板',
  'pages.searchTable.updateForm.ruleProps.typeLabel': '规则类型',
  'pages.searchTable.updateForm.schedulingPeriod.title': '设定调度周期',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '开始时间',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '请选择开始时间！',
  'pages.searchTable.titleDesc': '描述',
  'pages.searchTable.ruleName': '规则名称为必填项',
  'pages.searchTable.titleCallNo': '服务调用次数',
  'pages.searchTable.titleStatus': '状态',
  'pages.searchTable.nameStatus.default': '关闭',
  'pages.searchTable.nameStatus.running': '运行中',
  'pages.searchTable.nameStatus.online': '已上线',
  'pages.searchTable.nameStatus.abnormal': '异常',
  'pages.searchTable.titleUpdatedAt': '上次调度时间',
  'pages.searchTable.exception': '请输入异常原因！',
  'pages.searchTable.titleOption': '操作',
  'pages.searchTable.config': '配置',
  'pages.searchTable.subscribeAlert': '订阅警报',
  'pages.searchTable.title': '查询表格',
  'pages.searchTable.chosen': '已选择',
  'pages.searchTable.item': '项',
  'pages.searchTable.totalServiceCalls': '服务调用次数总计',
  'pages.searchTable.tenThousand': '万',
  'pages.searchTable.batchDeletion': '批量删除',
  'pages.searchTable.batchApproval': '批量审批',
  // 区域定义语言配置
  'pages.regions.searchTable.title': '区域定义',
  'pages.regions.form.add.title': '区域-新增',
  'pages.regions.form.edit.title': '区域-编辑',
  'pages.regions.form.code': '区域编码',
  'pages.regions.form.name': '区域名称',
  'pages.regions.form.name.placeholder': '请输入区域名称',
  'pages.regions.form.name.rule': '区域名称不能为空!',
  // 公司的语言配置
  'pages.company.searchTable.title': '公司管理',
  'pages.company.searchTable.capital': '注册资金(万元)',
  // 'pages.company.searchTable.capital ': '注册资金(万元)',
  'pages.company.searchTable.creditCode': '统一社会信用代码',
  'pages.company.form.addTitle': '公司管理-新增',
  'pages.company.form.editTitle': '公司管理-编辑',
  'pages.company.form.basicInfo': '公司基本信息',
  'pages.company.form.name': '公司名称',
  'pages.company.form.code': '公司编码',
  'pages.company.form.simpleName': '公司简称',
  'pages.company.form.contacts': '联系人',
  'pages.company.form.tel': '联系电话',
  'pages.company.form.website': '公司网址',
  'pages.company.form.OfficeTelephone': '办公电话',
  'pages.company.form.address': '办公地址',
  'pages.company.form.Legal': '法定代表人',

  'pages.company.form.RegistrationInformation': '注册信息',
  'pages.company.form.legalRepTitle': '法人职务',
  'pages.company.form.certificateCode': '公司代码',
  'pages.company.form.regAddress': '注册地址',
  'pages.company.form.registeredAapital': '注册资金',
  'pages.company.form.bankName': '开户银行',
  'pages.company.form.accountName': '账户名称',
  'pages.company.form.bankAccount': '银行账户',
  // rules

  'pages.company.form.rules.name': '公司名称不能为空',
  'pages.company.form.rules.contacts': '联系人不能为空',
  'pages.company.form.rules.tel': '电话不能为空',
  'pages.company.form.rules.format': '格式不正确',
  'pages.company.form.rules.limit25': '不能超过25个字符',
  'pages.company.form.rules.limit14': '不能超过14个字符',
  'pages.company.form.rules.formartCode': '请输入中文、英文或数字',
  'pages.company.form.rules.formartCode1': '请输入中文、英文',
  'pages.company.form.rules.formartCode2': '请输入数字或大写字母',
  'pages.company.form.rules.number': '请输入数字',
  'pages.company.form.rules.Nospaces': '不能出现空格',

  //提示语
  'pages.company.form.placeholder.certificateCode': '请输入公司统一社会信用代码',
  'pages.company.form.placeholder.regAddress': '请输入注册地址',
  'pages.company.form.placeholder.registeredAapital': '请输入注册资金',
  'pages.company.form.placeholder.bankName': '请输入开户银行',
  'pages.company.form.placeholder.accountName': '请输入账户名称',
  'pages.company.form.placeholder.bankAccount': '请输入银行账户',
  // 头部组件
  'pages.headerContent.navBarTip': '导航栏设置',
  'pages.headerContent.settingTitle': '导航栏设置',
  'pages.headerContent.dragBlock': '拖动区块调整显示顺序',
  'pages.headerContent.navBarModule': '导航栏模块',
};
