import {
  AccountBookOutlined,
  AimOutlined,
  AlertOutlined,
  AlibabaOutlined,
  AlipayCircleOutlined,
  AlipayOutlined,
  AliwangwangOutlined,
  AliyunOutlined,
  AndroidOutlined,
  AntCloudOutlined,
  AntDesignOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AppleOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  AreaChartOutlined,
  AudioMutedOutlined,
  AudioOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  BarcodeOutlined,
  BarsOutlined,
  BehanceOutlined,
  BehanceSquareOutlined,
  BellOutlined,
  BlockOutlined,
  BookOutlined,
  BorderlessTableOutlined,
  BorderOutlined,
  BranchesOutlined,
  BugOutlined,
  BuildOutlined,
  BulbOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CameraOutlined,
  CarOutlined,
  CarryOutOutlined,
  ChromeOutlined,
  CiCircleOutlined,
  CodepenCircleOutlined,
  DingdingOutlined,
  DotChartOutlined,
  DribbbleOutlined,
  DribbbleSquareOutlined,
  DropboxOutlined,
  FileTextOutlined,
  FundOutlined,
  FundProjectionScreenOutlined,
  GithubOutlined,
  GooglePlusOutlined,
  HistoryOutlined,
  HomeOutlined,
  Html5Outlined,
  IdcardOutlined,
  IeOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailOutlined,
  MediumWorkmarkOutlined,
  MessageOutlined,
  PayCircleOutlined,
  PicRightOutlined,
  PieChartOutlined,
  PoundCircleOutlined,
  ProfileOutlined,
  QqOutlined,
  RedditOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  ShareAltOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SketchOutlined,
  SkinOutlined,
  SkypeOutlined,
  SlackOutlined,
  SlackSquareOutlined,
  SlidersOutlined,
  TagsOutlined,
  TaobaoCircleOutlined,
  TaobaoOutlined,
  TwitterOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  WechatOutlined,
  WeiboCircleOutlined,
  WeiboOutlined,
  WeiboSquareOutlined,
  WindowsOutlined,
  YahooOutlined,
  YoutubeOutlined,
  YuqueOutlined,
  ZhihuOutlined,
  TeamOutlined,
  SolutionOutlined,
  SearchOutlined,
  ClusterOutlined,
  PartitionOutlined,
} from '@ant-design/icons';
export const IconMap = [
  { value: AreaChartOutlined, label: <AreaChartOutlined /> },
  { value: PartitionOutlined, label: <PartitionOutlined /> },
  { value: BarChartOutlined, label: <BarChartOutlined /> },
  { value: DotChartOutlined, label: <DotChartOutlined /> },
  { value: FundOutlined, label: <FundOutlined /> },
  { value: PieChartOutlined, label: <PieChartOutlined /> },
  { value: BarsOutlined, label: <BarsOutlined /> },
  { value: UsergroupAddOutlined, label: <UsergroupAddOutlined /> },
  { value: AppstoreAddOutlined, label: <AppstoreAddOutlined /> },
  { value: BookOutlined, label: <BookOutlined /> },
  { value: FileTextOutlined, label: <FileTextOutlined /> },
  { value: FundProjectionScreenOutlined, label: <FundProjectionScreenOutlined /> },
  { value: HistoryOutlined, label: <HistoryOutlined /> },
  { value: HomeOutlined, label: <HomeOutlined /> },
  { value: IdcardOutlined, label: <IdcardOutlined /> },
  { value: MessageOutlined, label: <MessageOutlined /> },
  { value: PayCircleOutlined, label: <PayCircleOutlined /> },
  { value: PicRightOutlined, label: <PicRightOutlined /> },
  { value: PoundCircleOutlined, label: <PoundCircleOutlined /> },
  { value: ProfileOutlined, label: <ProfileOutlined /> },
  { value: SettingOutlined, label: <SettingOutlined /> },
  { value: ShareAltOutlined, label: <ShareAltOutlined /> },
  { value: SlidersOutlined, label: <SlidersOutlined /> },
  { value: AndroidOutlined, label: <AndroidOutlined /> },
  { value: AppleOutlined, label: <AppleOutlined /> },
  { value: WindowsOutlined, label: <WindowsOutlined /> },
  { value: IeOutlined, label: <IeOutlined /> },
  { value: ChromeOutlined, label: <ChromeOutlined /> },
  { value: GithubOutlined, label: <GithubOutlined /> },
  { value: AliwangwangOutlined, label: <AliwangwangOutlined /> },
  { value: DingdingOutlined, label: <DingdingOutlined /> },
  { value: WeiboSquareOutlined, label: <WeiboSquareOutlined /> },
  { value: WeiboCircleOutlined, label: <WeiboCircleOutlined /> },
  { value: TaobaoCircleOutlined, label: <TaobaoCircleOutlined /> },
  { value: Html5Outlined, label: <Html5Outlined /> },
  { value: WeiboOutlined, label: <WeiboOutlined /> },
  { value: TwitterOutlined, label: <TwitterOutlined /> },
  { value: WechatOutlined, label: <WechatOutlined /> },
  { value: YoutubeOutlined, label: <YoutubeOutlined /> },
  { value: AlipayCircleOutlined, label: <AlipayCircleOutlined /> },
  { value: QqOutlined, label: <QqOutlined /> },
  { value: MediumWorkmarkOutlined, label: <MediumWorkmarkOutlined /> },
  { value: LinkedinOutlined, label: <LinkedinOutlined /> },
  { value: GooglePlusOutlined, label: <GooglePlusOutlined /> },
  { value: DropboxOutlined, label: <DropboxOutlined /> },
  { value: CodepenCircleOutlined, label: <CodepenCircleOutlined /> },
  { value: AlipayOutlined, label: <AlipayOutlined /> },
  { value: AppstoreOutlined, label: <AppstoreOutlined /> },
  { value: AntDesignOutlined, label: <AntDesignOutlined /> },
  { value: AliyunOutlined, label: <AliyunOutlined /> },
  { value: AliyunOutlined, label: <AliyunOutlined /> },
  { value: ZhihuOutlined, label: <ZhihuOutlined /> },
  { value: SlackOutlined, label: <SlackOutlined /> },
  { value: SlackSquareOutlined, label: <SlackSquareOutlined /> },
  { value: BehanceOutlined, label: <BehanceOutlined /> },
  { value: BehanceSquareOutlined, label: <BehanceSquareOutlined /> },
  { value: DribbbleOutlined, label: <DribbbleOutlined /> },
  { value: DribbbleSquareOutlined, label: <DribbbleSquareOutlined /> },
  { value: InstagramOutlined, label: <InstagramOutlined /> },
  { value: YuqueOutlined, label: <YuqueOutlined /> },
  { value: AlibabaOutlined, label: <AlibabaOutlined /> },
  { value: YahooOutlined, label: <YahooOutlined /> },
  { value: RedditOutlined, label: <RedditOutlined /> },
  { value: SketchOutlined, label: <SketchOutlined /> },
  { value: AccountBookOutlined, label: <AccountBookOutlined /> },
  { value: AimOutlined, label: <AimOutlined /> },
  { value: AlertOutlined, label: <AlertOutlined /> },
  { value: ApartmentOutlined, label: <ApartmentOutlined /> },
  { value: ApiOutlined, label: <ApiOutlined /> },
  { value: AppstoreOutlined, label: <AppstoreOutlined /> },
  { value: AudioOutlined, label: <AudioOutlined /> },
  { value: AudioMutedOutlined, label: <AudioMutedOutlined /> },
  { value: AuditOutlined, label: <AuditOutlined /> },
  { value: BankOutlined, label: <BankOutlined /> },
  { value: BarcodeOutlined, label: <BarcodeOutlined /> },
  { value: BellOutlined, label: <BellOutlined /> },
  { value: BlockOutlined, label: <BlockOutlined /> },
  { value: BorderOutlined, label: <BorderOutlined /> },
  { value: BorderlessTableOutlined, label: <BorderlessTableOutlined /> },
  { value: BranchesOutlined, label: <BranchesOutlined /> },
  { value: BugOutlined, label: <BugOutlined /> },
  { value: BuildOutlined, label: <BuildOutlined /> },
  { value: BulbOutlined, label: <BulbOutlined /> },
  { value: CalculatorOutlined, label: <CalculatorOutlined /> },
  { value: CalendarOutlined, label: <CalendarOutlined /> },
  { value: CarOutlined, label: <CarOutlined /> },
  { value: CameraOutlined, label: <CameraOutlined /> },
  { value: CarryOutOutlined, label: <CarryOutOutlined /> },
  { value: CiCircleOutlined, label: <CiCircleOutlined /> },
  { value: ShopOutlined, label: <ShopOutlined /> },
  { value: TagsOutlined, label: <TagsOutlined /> },
  { value: SkinOutlined, label: <SkinOutlined /> },
  { value: SecurityScanOutlined, label: <SecurityScanOutlined /> },
  { value: ShoppingCartOutlined, label: <ShoppingCartOutlined /> },
  { value: ClusterOutlined, label: <ClusterOutlined /> },
  { value: TeamOutlined, label: <TeamOutlined /> },
  { value: SolutionOutlined, label: <SolutionOutlined /> },
];

export const IconMapForEach = {
  AreaChartOutlined: <AreaChartOutlined />,
  PartitionOutlined: <PartitionOutlined />,
  ClusterOutlined: <ClusterOutlined />,
  TeamOutlined: <TeamOutlined />,
  SolutionOutlined: <SolutionOutlined />,
  SearchOutlined: <SearchOutlined />,
  BarChartOutlined: <BarChartOutlined />,
  DotChartOutlined: <DotChartOutlined />,
  FundOutlined: <FundOutlined />,
  PieChartOutlined: <PieChartOutlined />,
  BarsOutlined: <BarsOutlined />,
  UsergroupAddOutlined: <UsergroupAddOutlined />,
  AppstoreAddOutlined: <AppstoreAddOutlined />,
  BookOutlined: <BookOutlined />,
  FileTextOutlined: <FileTextOutlined />,
  FundProjectionScreenOutlined: <FundProjectionScreenOutlined />,
  HistoryOutlined: <HistoryOutlined />,
  HomeOutlined: <HomeOutlined />,
  IdcardOutlined: <IdcardOutlined />,
  MessageOutlined: <MessageOutlined />,
  PayCircleOutlined: <PayCircleOutlined />,
  PicRightOutlined: <PicRightOutlined />,
  PoundCircleOutlined: <PoundCircleOutlined />,
  ProfileOutlined: <ProfileOutlined />,
  SettingOutlined: <SettingOutlined />,
  ShareAltOutlined: <ShareAltOutlined />,
  SlidersOutlined: <SlidersOutlined />,
  UserOutlined: <UserOutlined />,
  UsergroupDeleteOutlined: <UsergroupDeleteOutlined />,
  MailOutlined: <MailOutlined />,
  AndroidOutlined: <AndroidOutlined />,
  AppleOutlined: <AppleOutlined />,
  WindowsOutlined: <WindowsOutlined />,
  IeOutlined: <IeOutlined />,
  ChromeOutlined: <ChromeOutlined />,
  GithubOutlined: <GithubOutlined />,
  AliwangwangOutlined: <AliwangwangOutlined />,
  DingdingOutlined: <DingdingOutlined />,
  WeiboSquareOutlined: <WeiboSquareOutlined />,
  WeiboCircleOutlined: <WeiboCircleOutlined />,
  TaobaoCircleOutlined: <TaobaoCircleOutlined />,
  Html5Outlined: <Html5Outlined />,
  WeiboOutlined: <WeiboOutlined />,
  TwitterOutlined: <TwitterOutlined />,
  WechatOutlined: <WechatOutlined />,
  YoutubeOutlined: <YoutubeOutlined />,
  AlipayCircleOutlined: <AlipayCircleOutlined />,
  TaobaoOutlined: <TaobaoOutlined />,
  SkypeOutlined: <SkypeOutlined />,
  QqOutlined: <QqOutlined />,
  MediumWorkmarkOutlined: <MediumWorkmarkOutlined />,
  LinkedinOutlined: <LinkedinOutlined />,
  GooglePlusOutlined: <GooglePlusOutlined />,
  DropboxOutlined: <DropboxOutlined />,
  CodepenCircleOutlined: <CodepenCircleOutlined />,
  AlipayOutlined: <AlipayOutlined />,
  AntDesignOutlined: <AntDesignOutlined />,
  AntCloudOutlined: <AntCloudOutlined />,
  AliyunOutlined: <AliyunOutlined />,
  ZhihuOutlined: <ZhihuOutlined />,
  SlackOutlined: <SlackOutlined />,
  SlackSquareOutlined: <SlackSquareOutlined />,
  BehanceOutlined: <BehanceOutlined />,
  BehanceSquareOutlined: <BehanceSquareOutlined />,
  DribbbleOutlined: <DribbbleOutlined />,
  DribbbleSquareOutlined: <DribbbleSquareOutlined />,
  InstagramOutlined: <InstagramOutlined />,
  YuqueOutlined: <YuqueOutlined />,
  AlibabaOutlined: <AlibabaOutlined />,
  YahooOutlined: <YahooOutlined />,
  RedditOutlined: <RedditOutlined />,
  SketchOutlined: <SketchOutlined />,
  AccountBookOutlined: <AccountBookOutlined />,
  AimOutlined: <AimOutlined />,
  AlertOutlined: <AlertOutlined />,
  ApartmentOutlined: <ApartmentOutlined />,
  ApiOutlined: <ApiOutlined />,
  AppstoreOutlined: <AppstoreOutlined />,
  AudioOutlined: <AudioOutlined />,
  AudioMutedOutlined: <AudioMutedOutlined />,
  AuditOutlined: <AuditOutlined />,
  BankOutlined: <BankOutlined />,
  BarcodeOutlined: <BarcodeOutlined />,
  BellOutlined: <BellOutlined />,
  BlockOutlined: <BlockOutlined />,

  BorderOutlined: <BorderOutlined />,
  BorderlessTableOutlined: <BorderlessTableOutlined />,
  BranchesOutlined: <BranchesOutlined />,
  BugOutlined: <BugOutlined />,
  BuildOutlined: <BuildOutlined />,
  BulbOutlined: <BulbOutlined />,
  CalculatorOutlined: <CalculatorOutlined />,
  CameraOutlined: <CameraOutlined />,
  CarOutlined: <CarOutlined />,
  CalendarOutlined: <CalendarOutlined />,
  CiCircleOutlined: <CiCircleOutlined />,
  ShopOutlined: <ShopOutlined />,
  TagsOutlined: <TagsOutlined />,
  SkinOutlined: <SkinOutlined />,
  SecurityScanOutlined: <SecurityScanOutlined />,
  ShoppingCartOutlined: <ShoppingCartOutlined />,
};
