/*
 * @Description:头部组件国际化资源View实体
 * @Author: lihua.ma
 * @Date: 2021-12-15 14:21:50
 * @Last Modified by: lihua.ma
 * @Last Modified time: 2021-12-15 14:52:23
 * @Department: 北京亿高索尔科技有限公司西安研发中心
 */

import { useIntl } from 'umi';

export class HeaderViewEntity {
  //#region  属性定义
  welcome = GetPrototype('menu.home', '首页');

  navBarTip = GetPrototype('pages.headerContent.navBarTip', '导航栏设置');

  settingTitle = GetPrototype('pages.headerContent.settingTitle', '自定义导航栏');

  dragBlock = GetPrototype('pages.headerContent.dragBlock', '拖动区块调整显示顺序');

  navBarModule = GetPrototype('pages.headerContent.navBarModule', '导航栏模块');
  //#endregion
}
//资源转换公共方法
function GetPrototype(intelId: string, defMsg: string) {
  return useIntl()
    .formatMessage({
      id: intelId,
      defaultMessage: defMsg,
    })
    .toString();
}
