import type { AbnormalModelState, AbnormalModelType } from './data';
import {
  getList,
  editAbnormal,
  updateStatus,
  getAbnormal,
  deleteAbnormal,
  addAbnormal,
} from './services';
// pos信息model
const AbnormalModel: AbnormalModelType = {
  // 空间命名
  namespace: 'Abnormal',
  // 定义state
  state: {
    // pos信息对象
    abnormal: {},
    // 控制弹窗新增编辑的变量
    isEdit: false,
    // 小票联数下拉
    ticketsNumber: [
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 },
      { label: 5, value: 5 },
      { label: 6, value: 6 },
    ],
  },
  effects: {
    // 获取表格数据
    *getList({ payload }: any, { call }: any) {
      const params = {
        ...payload,
        pageNumber: payload.current,
      };
      // 删除无用参数
      delete params.current;
      // 发送请求，获取表格数据
      const res = yield call(getList, params);
      // 返回表格数据
      return {
        data: res.data?.items || [],
        total: res.data?.totalElements,
        success: res.successful,
      };
    },
    // 编辑pos信息
    *edit({ payload }: any, { call }: any) {
      // 发送请求，进行表单数据编辑
      return yield call(editAbnormal, payload);
    },
    // 新增pos信息
    *add({ payload }: any, { call }: any) {
      // 发送请求，进行表单数据新增
      return yield call(addAbnormal, payload);
    },
    // pos信息状态修改
    *changeState({ payload }: any, { call }: any) {
      // 处理修改状态所需的参数
      const params = {
        status: payload.status ? 1 : 9,
        posId: payload.posId,
        plazaId: payload.plazaId,
      };
      // 发送请求，修改pos信息状态
      return yield call(updateStatus, params);
    },
    // 信息管理删除
    *delete({ payload }: any, { call }: any) {
      // 处理修改状态所需的参数
      const params = {
        posId: payload.posId,
        plazaId: payload.plazaId,
      };
      // 发送请求，删除pos信息
      return yield call(deleteAbnormal, params);
    },
    // 查询pos信息详情
    *init({ payload }: any, { call }: any) {
      // 处理查询表单信息所需的参数
      const params = {
        plazaId: payload.plazaId,
        posId: payload.posId,
      };
      // 发送请求，查询表单信息
      return yield call(getAbnormal, params);
    },
  },
  reducers: {
    // 更新state
    save(state: AbnormalModelState, action: any) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export default AbnormalModel;
