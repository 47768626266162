export default {
  // 表格公共语言配置
  'pages.searchTable.new': 'New',
  'pages.searchTable.name_code.search': 'Please enter name and code',
  'pages.searchTable.index': 'Index',
  'pages.searchTable.columns.code': 'Code',
  'pages.searchTable.columns.name': 'Name',
  'pages.searchTable.columns.remark': 'Remarks',
  'pages.searchTable.columns.operator': 'Operator',
  'pages.searchTable.columns.operation_time': 'Operation time',
  'pages.searchTable.columns.state': 'State',
  'pages.searchTable.columns.disable': 'Disable',
  'pages.searchTable.columns.enable': 'Enable',
  'pages.searchTable.columns.option': 'Option',
  'pages.searchTable.columns.edit': 'Edit',
  'pages.searchTable.columns.delete': 'Delete',
  'pages.searchTable.message.success': 'success',
  // 表单公共语言配置
  'pages.form.remark': 'Remarks',
  'pages.form.remark.placeholder': 'Please enter remarks',
  'pages.form.sort': 'Sorting weight',
  'pages.form.sort.placeholder': 'Please enter sorting weight',
  'pages.form.sort.rule': 'Sorting weight cannot be empty!',
  'pages.layouts.userLayout.title': 'Eagle Soar Trends.loyalty For Saas',
  'pages.login.title': 'Trends.loyalty For Saas',
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.username.placeholder': 'Username',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.capt.placeholder': 'Imge Verification Code',
  'pages.login.capt.required': 'Please input imge verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
  'pages.welcome.advancedComponent': 'Advanced Component',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.advancedLayout': 'Advanced Layout',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.',
  'pages.searchTable.createForm.newRule': 'New Rule',
  'pages.searchTable.updateForm.ruleConfig': 'Rule configuration',
  'pages.searchTable.updateForm.basicConfig': 'Basic Information',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Rule Name',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Please enter the rule name!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Rule Description',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Please enter at least five characters',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Please enter a rule description of at least five characters!',
  'pages.searchTable.updateForm.ruleProps.title': 'Configure Properties',
  'pages.searchTable.updateForm.object': 'Monitoring Object',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Rule Template',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Rule Type',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Set Scheduling Period',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Starting Time',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Please choose a start time!',
  'pages.searchTable.titleDesc': 'Description',
  'pages.searchTable.ruleName': 'Rule name is required',
  'pages.searchTable.titleCallNo': 'Number of Service Calls',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'default',
  'pages.searchTable.nameStatus.running': 'running',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'abnormal',
  'pages.searchTable.titleUpdatedAt': 'Last Scheduled at',
  'pages.searchTable.exception': 'Please enter the reason for the exception!',
  'pages.searchTable.titleOption': 'Option',
  'pages.searchTable.config': 'Configuration',
  'pages.searchTable.subscribeAlert': 'Subscribe to alerts',
  'pages.searchTable.title': 'Enquiry Form',
  'pages.searchTable.chosen': 'chosen',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Total Number of Service Calls',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'bacth deletion',
  'pages.searchTable.batchApproval': 'batch approval',
  // 区域定义语言配置
  'pages.regions.searchTable.title': 'Region',
  'pages.regions.form.add.title': 'Region-New',
  'pages.regions.form.edit.title': 'Region-Edit',
  'pages.regions.form.code': 'Region coding',
  'pages.regions.form.name': 'Region name',
  'pages.regions.form.name.placeholde': 'Please enter region name',
  'pages.regions.form.name.rule': 'Region name cannot be empty!',

  // 公司的语言配置
  'pages.company.searchTable.title': 'Company',
  'pages.company.searchTable.capital ': 'Registered capital (10000 yuan)',
  'pages.company.searchTable.creditCode': 'Uniform social credit code',
  'pages.company.form.addTitle': 'Company-add',
  'pages.company.form.editTitle': 'Company-edit',
  'pages.company.form.basicInfo': 'Basic information of the company',
  'pages.company.form.name': 'Company name',
  'pages.company.form.code': 'Company code',
  'pages.company.form.simpleName': 'Company abbreviation',
  'pages.company.form.contacts': 'contacts',
  'pages.company.form.tel': 'contact number',
  'pages.company.form.website': 'Company website',
  'pages.company.form.OfficeTelephone': 'Office telephone',
  'pages.company.form.address': 'Office address',
  'pages.company.form.Legal': 'Legal representative',
  'pages.company.form.RegistrationInformation': 'Registration information',
  'pages.company.form.legalRepTitle': 'Legal person position',
  'pages.company.form.certificateCode': 'Company code',
  'pages.company.form.regAddress': 'Company Registered Address',
  'pages.company.form.registeredAapital': 'Registered capital',
  'pages.company.form.bankName': 'Bank of deposit',
  'pages.company.form.accountName': 'title of account',
  'pages.company.form.bankAccount': 'bank account',
  'pages.company.form.rules.name': 'Company name is required!',

  'pages.company.form.rules.contacts': 'contacts is required!',
  'pages.company.form.rules.tel': 'contactTel is required!',
  'pages.company.form.rules.format': 'Incorrect format!',
  'pages.company.form.rules.limit25': 'No more than 25 characters',
  'pages.company.form.rules.limit14': 'No more than 14 characters',
  'pages.company.form.rules.formartCode': 'Please enter Chinese, English or numbers',
  'pages.company.form.rules.formartCode1': 'Please input Chinese or English',
  'pages.company.form.rules.formartCode2': 'Please enter numbers or uppercase letters',
  'pages.company.form.rules.number': 'please enter a number',
  'pages.company.form.rules.Nospaces': 'No spaces are allowed',

  //提示语
  'pages.company.form.placeholder.certificateCode':
    "Please enter the company's unified social credit code",
  'pages.company.form.placeholder.regAddress': 'Please enter your registered address',
  'pages.company.form.placeholder.registeredAapital': 'Please enter the registered capital',
  'pages.company.form.placeholder.bankName': 'Please enter the Bank of deposit',
  'pages.company.form.placeholder.accountName': 'Please enter the account name',
  'pages.company.form.placeholder.bankAccount': 'Please enter bank account',
  // 头部组件
  'pages.headerContent.navBarTip': 'Navigation Bar settings',
  'pages.headerContent.settingTitle': 'Customize Navigation Bar',
  'pages.headerContent.dragBlock': 'Drag blocks to adjust display order',
  'pages.headerContent.navBarModule': 'Navigation Bar Module',
};
