/*
 * @Description:动态菜单逻辑处理
 * @Author: lihua.ma
 * @Date: 2021-12-08 10:06:14
 * @Last Modified by: lihua.ma
 * @Last Modified time: 2022-08-09 11:14:22
 * @Department: 北京亿高索尔科技有限公司西安研发中心
 */

import { IconMapForEach } from '@/utils/icon';
import { message } from 'antd';
import { request } from 'umi';

//#region 菜单请求，逻辑处理
/** 递归处理菜单格式 */
const menuRecursion = (menus: any) => {
  const arr: any = [];
  // 遍历menus
  menus?.forEach((item: any) => {
    const obj: any = {};
    obj.id = item.sysResourceId;
    // 获取路径
    obj.path = item.resourcePath;
    // 获取图标,将图标转换
    obj.icon = IconMapForEach[item.resourceIocn as string];
    // 获取name
    obj.name = item.sysResourceName;
    // 获取子路由
    obj.routes = item.menuList;
    // 若菜单长度大于0，进行递归，处理子级菜单
    if (item.menuList?.length > 0) {
      obj.routes = menuRecursion(item.menuList);
    }
    // 若菜单长度大于0，进行递归，处理子级菜单
    if (item.pageList?.length > 0) {
      obj.routes = menuRecursion(item.pageList);
    }
    arr.push(obj);
  });
  return arr;
};
/** 请求菜单，处理菜单格式 */
export async function getMenus() {
  // 发送请求，获取请求的数据
  const response = await request<Record<string, any>>(`${BASE_URL}/current/getMenu`);
  if (response?.successful) {
    // 将菜单和按钮权限进行解构
    const { buttons, menus } = response.data;
    // 将按钮权限存储
    sessionStorage.setItem('buttons', buttons);
    return menuRecursion(menus);
  } else {
    message.error(response?.msg);
  }
}

//#endregion

/** 请求租户信息 */
export async function getTenant() {
  // 发送请求，获取请求的数据
  const response = await request(`${BASE_URL}/ess/getTenant`, {
    method: 'post',
  });
  const { tenantName, tenantLogo } = response.data;
  return {
    title: tenantName || '',
    log: tenantLogo || '',
  };
}
// 导航栏菜单请求接口
export async function getNavMenus() {
  const menus = [
    // { key: 1, name: '基础配置', icon: '', path: '/basic' },
    // { key: 2, name: '用户权限', icon: 'TeamOutlined', path: '/users' },
    // { key: 3, name: '会员营销', icon: '', path: '/crm' },
    // { key: 4, name: 'pos系统', icon: '', path: '/pos' },
  ];
  return menus;
}
// 导航栏配置菜单请求接口
export async function getNavList() {
  const menus = [
    { id: 1, name: '公司管理' },
    { id: 2, name: '区域定义' },
    { id: 3, name: '用户管理' },
    { id: 4, name: '角色管理' },
    { id: 5, name: '经营渠道' },
    { id: 6, name: '系统参数配置' },
    { id: 7, name: '会员检索' },
  ];
  return menus || [];
}
// 请求用户配置导航栏的菜单
export async function getSettingMenus() {
  const menus = [
    {
      id: '0-1',
      name: '基础配置',
      children: [
        { id: 1, name: '公司管理', check: true },
        { id: 2, name: '区域定义', check: true },
        { id: 9, name: '项目管理', check: false },
        { id: 10, name: '商业楼栋管理', check: false },
      ],
    },
    {
      id: '0-2',
      name: '系统管理',
      children: [
        { id: 5, name: '经营渠道', check: true },
        { id: 6, name: '系统参数配置', check: true },
        { id: 11, name: '成长值规则配置', check: false },
        { id: 12, name: '会员异动原因', check: false },
      ],
    },
    {
      id: '0-3',
      name: '用户权限',
      children: [
        { id: 3, name: '用户管理', check: true },
        { id: 4, name: '角色管理', check: true },
      ],
    },
    {
      id: '0-4',
      name: '会员中心',
      children: [
        { id: 7, name: '会员检索', check: true },
        { id: 8, name: '会员异常积分', check: false },
      ],
    },
  ];

  return menus || [];
}
