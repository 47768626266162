/*
 * @Description:异常预警接口逻辑处理页面
 * @Author: lihua.ma
 * @Date: 2021-12-16 13:15:57
 * @Last Modified by: lihua.ma
 * @Last Modified time: 2021-12-16 15:11:37
 * @Department: 北京亿高索尔科技有限公司西安研发中心
 */
import { message } from 'antd';
import { request } from 'umi';
/** 获取异常预警配置列表 */
export async function getList(body: any) {
  const params = {
    ...body,
    pageNumber: body.current,
  };
  delete params.current;
  const response = await request(`${BASE_URL}/Alarm/getPagList`, {
    method: 'POST',
    data: params,
  });
  if (!response.successful) {
    message.warning(response.msg);
  }
  return {
    data: response.data?.items || [],
    total: response.data?.totalElements,
    success: response.successful,
  };
}
/** 预警配置新增新增 */
export async function addAbnormal(body: any) {
  return request(`${BASE_URL}/Alarm/addAlarm`, {
    method: 'POST',
    data: body,
  });
}
/** 预警配置编辑 */
export async function editAbnormal(body: any) {
  return request(`${BASE_URL}/Alarm/modifyAlarm`, {
    method: 'POST',
    data: body,
  });
}
/** 预警配置详情查询 */
export async function getAbnormal(body: any) {
  const res = await request(`${BASE_URL}/Alarm/getAlarmDetails`, {
    method: 'POST',
    data: body,
  });
  if (!res.successful) {
    message.warning(res.msg);
  }
  return res.data || {};
}
/** 预警配置状态修改 */
export async function updateStatus(body: any) {
  const params = {
    riskConfId: body.riskConfId,
    state: body.state ? 0 : 1,
    version: body?.version,
  };
  return request(`${BASE_URL}/Alarm/modifyAlarmState`, {
    method: 'POST',
    data: params,
  });
}
/**预警配置删除 */
export async function deleteAbnormal(body: any) {
  return request(`${BASE_URL}/Alarm/delete`, {
    method: 'POST',
    data: body,
  });
}

//预警方式
export async function getAlarmModelList() {
  const res = await request(`${BASE_URL}/Alarm/getAlarmModelList`, {
    method: 'get',
  });
  if (!res.successful) {
    message.warning(res.msg);
  }
  return res.data || [];
}

//预警类型

export async function getAlarmTypeList() {
  const res = await request(`${BASE_URL}/Alarm/getAlarmTypeList`, {
    method: 'get',
  });
  if (!res.successful) {
    message.warning(res.msg);
  }
  return res.data || [];
}
