import { HomeOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styles from './index.less';
import useMergedState from 'rc-util/es/hooks/useMergedState';
import { getNavMenus } from '@/services/ant-design-pro/menu';
import { useRequest } from 'ahooks';
import { Link, history } from 'umi';
import { Menu } from 'antd';
import { IconMapForEach } from '@/utils/icon';
import { HeaderViewEntity } from './HeaderViewEntity';
import '../../pages/common.less';
export type HeaderContent = {
  collapse?: boolean;
  onCollapse?: (collapsed: boolean) => void;
};

const Index = (props: any) => {
  const path = history.location.pathname;
  let selectpath = '';
  if (path.includes('/basic')) {
    selectpath = '/basic';
  } else if (path.includes('/users')) {
    selectpath = '/users';
  } else if (path.includes('/pos')) {
    selectpath = '/pos';
  } else {
    selectpath = '/welcome';
  }

  const [collapsed, setCollapsed] = useMergedState<boolean>(props.collapse ?? false, {
    value: props.collapse,
    onChange: props.onCollapse,
  });
  const { data: menu } = useRequest(getNavMenus);
  // 实例化国际化资源对象类
  const headerEt = new HeaderViewEntity();
  return (
    <div>
      <div className={styles.header_menu}>
        <div className={styles.tops} onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
        <Menu mode="horizontal" selectedKeys={[selectpath]}>
          <Menu.Item key="/welcome" icon={<HomeOutlined />}>
            <Link to="/welcome">{headerEt.welcome}</Link>
          </Menu.Item>
          {menu?.map((item) => {
            return (
              <Menu.Item key={item.path} icon={IconMapForEach[item.icon as string]}>
                <Link to={item.path}>{item.name}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

export default Index;
