// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/mnt/jenkins-home/workspace/single-pos-miguli/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user/login",
            "layout": false,
            "name": "login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/user/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user",
            "redirect": "/user/login",
            "exact": true
          },
          {
            "name": "register-result",
            "icon": "smile",
            "path": "/user/register-result",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register-result' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/user/register-result'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "register",
            "icon": "smile",
            "path": "/user/register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/user/register'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/pos/management",
        "name": "POS管理",
        "icon": "dashboard",
        "routes": [
          {
            "name": "POS信息管理",
            "icon": "smile",
            "path": "/pos/management/information",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__information' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/management/information'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "收银员管理",
            "icon": "smile",
            "path": "/pos/management/cashier",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__cashier' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/management/cashier'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "pos版本管理",
            "icon": "smile",
            "path": "/pos/management/versionManager",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__version' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/management/version'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/pos/configuration",
        "name": "POS配置",
        "icon": "dashboard",
        "routes": [
          {
            "name": "显示屏幕配置",
            "icon": "smile",
            "path": "/pos/configuration/homePage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__configuration__publicity' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/configuration/publicity'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "显示屏幕配置",
            "icon": "smile",
            "path": "/configuration/publicity",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__configuration__publicity' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/configuration/publicity'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "小票模板配置",
            "icon": "smile",
            "path": "/pos/configuration/receipts",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__configuration__receipt' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/configuration/receipt'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "新增小票模板",
            "icon": "smile",
            "path": "/pos/configuration/receipts/addTemplate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__configuration__receipt__addTemplate' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/configuration/receipt/addTemplate'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/pos/monitoring",
        "name": "POS监控",
        "icon": "dashboard",
        "routes": [
          {
            "name": "POS状态监控",
            "icon": "smile",
            "path": "/pos/monitoring/statusMonitoring",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring__statusMonitoring' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/monitoring/statusMonitoring'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "异常预警配置",
            "icon": "smile",
            "path": "/pos/monitoring/abnormal",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring__abnormal' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/monitoring/abnormal'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "异常预警配置",
            "icon": "smile",
            "path": "/pos/monitoring/abnormal/edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring__abnormal__edit' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/monitoring/abnormal/edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "POS交易监控",
            "icon": "smile",
            "path": "/pos/monitoring/transaction",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring__transaction' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/monitoring/transaction'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "POS日志管理",
            "icon": "smile",
            "path": "/pos/monitoring/journal",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring__journal' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/monitoring/journal'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "终端授权控制",
            "icon": "smile",
            "path": "/pos/monitoring/terminal",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring__terminal' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/monitoring/terminal'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/pos/saleReport",
        "name": "销售报表",
        "icon": "dashboard",
        "routes": [
          {
            "name": "商场销售报表",
            "icon": "smile",
            "path": "/pos/saleReport/market",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__saleReport__market' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/saleReport/market'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "商铺销售报表(商品)",
            "icon": "smile",
            "path": "/pos/saleReport/shopGoods",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__saleReport__shopGoods' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/saleReport/shopGoods'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "商铺销售报表(交易)",
            "icon": "smile",
            "path": "/pos/saleReport/shopDeal",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__saleReport__shopDeal' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/saleReport/shopDeal'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "终端销售报表",
            "icon": "smile",
            "path": "/pos/saleReport/terminal",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__saleReport__terminal' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/saleReport/terminal'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "支付方式销售汇总报表",
            "icon": "smile",
            "path": "/pos/saleReport/payment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__saleReport__payment' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/saleReport/payment'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "销售时段排名",
            "icon": "smile",
            "path": "/pos/saleReport/rank",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__saleReport__rank' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/saleReport/rank'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/pos/promotion",
        "name": "促销管理",
        "icon": "dashboard",
        "routes": [
          {
            "name": "促销活动定义",
            "icon": "smile",
            "path": "/pos/promotion/activity",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__activity' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/promotion/activity'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "促销活动新增",
            "icon": "smile",
            "path": "/pos/promotion/activity/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__activity__add' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/promotion/activity/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "促销活动详情",
            "icon": "smile",
            "path": "/pos/activity/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__activity__detail' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/promotion/activity/detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "促销类型顺序定义",
            "icon": "smile",
            "path": "/pos/promotion/type",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__type' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/promotion/type'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "促销档期定义",
            "icon": "smile",
            "path": "/pos/promotion/schedule",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__schedule' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/promotion/schedule'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/pos/salesManagement",
        "name": "销售管理",
        "icon": "dashboard",
        "routes": [
          {
            "name": "销售日报",
            "icon": "smile",
            "path": "/pos/salesManagement/daily",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__salesManagement__daily' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/salesManagement/daily'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "销售数据导入",
            "icon": "smile",
            "path": "/pos/salesManagement/storeAudit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__salesManagement__storeAudit' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/salesManagement/storeAudit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "销售数据导入审核",
            "icon": "smile",
            "path": "/pos/salesManagement/storeAudit/edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__salesManagement__storeAudit__Edit' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/salesManagement/storeAudit/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/pos/goods",
        "name": "商品管理",
        "icon": "dashboard",
        "routes": [
          {
            "name": "商品分类",
            "icon": "smile",
            "path": "/pos/goods/classify",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__goods__classify' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/goods/classify'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "商品列表",
            "icon": "smile",
            "path": "/pos/goods/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__goods__list' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/goods/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "商品列表新增/编辑",
            "icon": "smile",
            "path": "/pos/goods/list/edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__goods__list__Edit' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/goods/list/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/users/baseUser",
        "name": "用户权限",
        "icon": "user",
        "routes": [
          {
            "path": "/users/baseUser/user",
            "name": "用户管理",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__baseUser__user' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/baseUser/user'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/users/baseUser/user/edit",
            "name": "用户管理新增/编辑",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__baseUser__user__Edit' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/baseUser/user/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/baseUser/role",
            "name": "角色管理",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__baseUser__role' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/baseUser/role'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/users/baseUser/role/edit",
            "name": "角色管理新增/编辑",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__baseUser__role__Edit' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/baseUser/role/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/users/account/userInfo",
        "name": "个人信息",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounts__userInfo' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/accounts/userInfo'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/basic/basics",
        "name": "基础管理",
        "icon": "smile",
        "routes": [
          {
            "path": "/basic/basics/company",
            "name": "公司管理",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__company' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/company'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/company/edit",
            "name": "公司管理表单",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__company__edit' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/company/edit'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/plaza/position",
            "name": "位置信息配置",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__project__position' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/project/position'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/regions",
            "name": "区域定义",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__regions' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/regions'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/plaza",
            "name": "项目管理",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__project' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/project'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/payment",
            "name": "支付方式",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__payment' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/payment'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/brand",
            "name": "品牌管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__brand' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/brand'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/building",
            "name": "楼栋管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__building' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/building'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/floor",
            "name": "楼层管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__floor' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/floor'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/basics/trade",
            "name": "业态管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__trade' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/basic/trade'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/basic/shop",
        "name": "商铺协议",
        "icon": "smile",
        "routes": [
          {
            "path": "/basic/shop/management",
            "name": "商铺管理",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shop__management' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/shop/management'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/basic/shop/management/edit",
            "name": "新增商铺",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shop__management__Edit' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/shop/management/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/basic/shop/management/update",
            "name": "商铺配置",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shop__management__SettingShop' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/shop/management/SettingShop'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "account",
        "icon": "user",
        "path": "/account",
        "routes": [
          {
            "path": "/account",
            "redirect": "/account/center",
            "exact": true
          },
          {
            "name": "center",
            "icon": "smile",
            "path": "/account/center",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__center' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/account/center'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "settings",
            "icon": "smile",
            "path": "/account/settings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__settings' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/account/settings'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/account/resetPassword",
            "name": "重置密码",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__ResetPassword' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/account/ResetPassword'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "首页",
        "path": "/welcome",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/welcome",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "path": "/basic",
        "redirect": "/basic/basics/plaza",
        "exact": true
      },
      {
        "path": "/users",
        "redirect": "/users/baseUser/user",
        "exact": true
      },
      {
        "path": "/pos",
        "redirect": "/pos/management/information",
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/mnt/jenkins-home/workspace/single-pos-miguli/src/pages/404'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
