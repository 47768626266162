import type { TransactionModelState, TransactionModelType } from './data';
import { getList, getTransaction } from './services';
// 交易监控model
const TransactionModel: TransactionModelType = {
  // 空间命名
  namespace: 'Transaction',
  // 定义state
  state: {
    // 交易监控对象
    transaction: {},
    // 控制弹窗显示隐藏的变量
    visible: false,
  },
  effects: {
    // 获取表格数据
    *getList({ payload }: any, { call }: any) {
      const params = {
        // 将搜索转换为所需要的字段名称
        ...payload,
        pageNumber: payload.current,
      };
      // 删除无用参数
      delete params.current;
      // 发送请求，获取表格数据
      const res = yield call(getList, params);
      // 返回表格数据
      return {
        data: res.data?.items || [],
        total: res.data?.totalElements,
        success: res.successful,
      };
    },
    // 查询交易监控详情
    *init({ payload }: any, { call }: any) {
      // 处理查询表单信息所需的参数
      const params = {
        plazaId: payload.plazaId,
        confScreenId: payload.confScreenId,
      };
      // 发送请求，查询表单信息
      return yield call(getTransaction, params);
    },
  },
  reducers: {
    // 更新state
    save(state: TransactionModelState, action: any) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export default TransactionModel;
