// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined'

export default {
  SmileOutlined,
DashboardOutlined,
UserOutlined
}
    